//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { imgChange } from "@/commons";
export default {
  components: {
    CContent: () => import("@/components/CContent"),
    CPages: () => import("@/components/CPages"),
    TaskModal: () => import("./task-modal"),
  },
  props: {
    teamOptions: {
      type: Array,
      default: null,
    },
    //是否是成员任务
    isMember: {
      type: Boolean,
      default: false,
    },
    //团队id
    teamValue: {
      type: Number,
      default: null,
    },
    //成员任务时候的用户id
    userId: {
      type: Number,
      default: null,
    },
  },
  data() {
    return {
      IsRealTimeScreenshot: true,
      searchVal: null, //搜索的任务
      loading: false,
      indexData: {
        type: "", // Add Edit
        name: "",
        departmentCode: "",
      },
      tableData: [],
      pageData: {
        pageIndex: 1,
        pageSize: 10,
        totalNum: 0,
      },
      cellStyle: {
        textAlign: "center",
      },
    };
  },
  computed: {
    user() {
      return this.$store.state.user;
    },
  },
  watch: {
    //成员任务的团队切换
    teamValue: {
      immediate: true,
      handler: function (val, ovl) {
        if (val && val != ovl) {
          this.teamValue = val;
          this.handleSearch();
        }
      },
    },
    //成员任务的成员切换
    userId(val, ovl) {
      if (val != ovl) {
        this.handleSearch();
      }
    },
  },
  mounted() {
    if (this.$route.query.isInfo && this.$route.query.teamValue) {
      this.teamValue = JSON.parse(this.$route.query.teamValue);
    }
  },
  methods: {
    getStatus(row) {
      return this.$D.ITEM("task_status", row.Schedule);
    },
    imgChange,
    /**
     * 成员任务团队切换
     */
    memberTeamChange(val) {
      this.IsRealTimeScreenshot = val;
    },
    /**
     * 团队切换
     */
    handleTeamChange(val) {
      const team = this.teamOptions.find((m) => m.Id == val);
      if (team) {
        this.IsRealTimeScreenshot = team.IsRealTimeScreenshot; //是否显示实时截图
      }
      this.handleSearch();
    },
    xmodalLoaded() {
      if (
        this.$route.query.isInfo &&
        this.$route.query.teamValue &&
        this.$route.query.task
      ) {
        this.$http
          .post("/Task/GetTaskDetail.ashx", {
            teamId: JSON.parse(this.$route.query.teamValue),
            Id: JSON.parse(this.$route.query.task),
          })
          .then((resp) => {
            if (resp.res == 0) {
              this.handleEdit(resp.data);
            }
          });
      }
    },
    /**
     * 启用、禁用任务
     */
    handleEnable(val, type) {
      const data = {
        teamId: this.teamValue,
        Id: val.Id,
        operation: type,
      };
      this.$http.post("/Task/EnableTask.ashx", data).then((resp) => {
        if (resp.res == 0) {
          this.$message({
            showClose: true,
            message: `${type ? "启用" : "禁用"}任务成功！`,
            type: "success",
          });
          this.getDataList();
        }
      });
    },
    // 删除某一行
    handleDelt(row) {
      this.$confirm("此操作将删除此任务, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          const data = {
            teamId: this.teamValue,
            Ids: [row.Id],
          };
          this.comDelete(data);
        })
        .catch(() => {});
    },
    comDelete(params) {
      this.$http.post("/Task/DelTask.ashx", params).then((result) => {
        if (result.res == 0) {
          this.$message({
            showClose: true,
            message: "删除任务成功！",
            type: "success",
          });
          this.getDataList();
        }
      });
    },
    // 新增
    addClick() {
      this.openWin("ad");
    },
    // 编辑
    handleEdit(row) {
      this.openWin("ed", row.Id, row);
    },
    // 打开窗口
    openWin(ty, code, row) {
      this.indexData = {
        type: ty === "ad" ? "Add" : `${this.isMember ? "" : "Edit"}`,
        name:
          ty === "ad"
            ? "添加任务"
            : `${this.isMember ? "任务详情" : "编辑任务"}`,
        departmentCode: code,
        row: row,
        xModalName: "taskM",
      };
      this.$modal.show("taskM");
      this.$nextTick(() => {
        this.$refs.taskM.changeEditState();
      });
    },

    // 查询
    handleSearch() {
      this.pageData.pageIndex = 1;
      this.getDataList();
    },
    // 获取列表
    getDataList() {
      if (!this.teamValue) {
        this.$message({
          message: "请先选择团队",
          type: "warning",
        });
        return;
      }
      const data = {
        teamId: this.teamValue,
        pageIndex: this.pageData.pageIndex,
        pageSize: this.pageData.pageSize,
        name: this.searchVal,
        usId: this.isMember ? this.userId : null,
        type: (this.isMember && this.userId) || !this.isMember ? 2 : 1,
      };
      this.loading = true;
      this.$http
        .post("/Task/GetTaskList.ashx", data)
        .then((result) => {
          if (result.res == 0) {
            this.tableData = result.data.Data;
            this.pageData.totalNum = result.data.TotalCount;
          }
        })
        .finally(() => (this.loading = false));
    },
    /**
     * 分页
     */
    handlePaginationChange(val) {
      this.pageData = val;
      this.getDataList();
    },
  },
};
